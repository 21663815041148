<template>
  <v-container>
    <template v-if="loading">
      <v-row justify="center" align="center" align-content="center">
        <v-progress-circular color="primary"
                             width="3"
                             indeterminate
                             size="42">
        </v-progress-circular>
      </v-row>
    </template>
    <template v-else>
      <template v-if="visitors.length > 0">
        <v-row>
          <template v-for="(item, index) in visitors">
            <v-col cols="12" sm="4" md="3" :key="index">
              <v-card class="fill-height">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <div style="height: 80px; width: 80px; border-radius: 40px; line-height: 80px"
                           class="secondary mx-auto text-center display-1 white--text">
                        {{ `${item.lastName.substr(0,1)}${item.firstName.substr(0,1)}` }}
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-center font-weight-bold py-0 f-18">
                      {{ `${item.lastName} ${item.firstName}` }}
                    </v-col>
                    <v-col cols="12">
                      <v-list dense>
                        <v-list-item class="px-0">
                          <v-list-item-avatar class="mr-2">
                            <v-hover v-slot="{ hover }">
                              <v-icon @click="sendEmail(item.email)"
                                      :class="{ 'on-hover': hover }" :color="hover ? 'primary' : ''">
                                mdi-email-outline
                              </v-icon>
                            </v-hover>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-2">
                              {{ item.email }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              E-mail cím
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                          <v-list-item-avatar class="mr-2">
                            <v-hover v-slot="{ hover }">
                              <v-icon @click="phoneVisitor(item.phone)"
                                      :class="{ 'on-hover': hover }"
                                      :color="hover ? 'primary' : ''">
                                mdi-phone
                              </v-icon>
                            </v-hover>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-2">
                              {{ item.phone }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Telefonszám
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                          <v-list-item-avatar class="mr-2">
                            <v-icon>
                              mdi-cash
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-2">
                              {{ item.netSalary }} Ft
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Nettó bérigény
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                          <v-list-item-avatar class="mr-2">
                            <v-icon>
                              mdi-account-voice
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-2">
                              {{ item.sourceSponsor ? `${item.sourceSponsor} - Dolgozói ajánlás` : item.sourceOther ? `${item.sourceOther}` : `${item.sourcePage}` }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Honnan értesült?
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" v-if="item.cv">
                      <v-btn color="primary"
                             block
                             @click="startDownloadDocument(item, 'cv')">
                        Önéletrajz
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="item.motivationLetter">
                      <v-btn color="primary"
                             block
                             @click="startDownloadDocument(item, 'ml')">
                        Motivációs levél
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-else>
        <v-row justify="center" align="center" align-content="center">
          Nincs megjeleníthető jelentkező.
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'CareerJobVisitorsPage',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    if (this.$route.params.jobId) {
      this.visitors = await this.loadVisitors();
      this.loading = false;
    } else {
      this.$router.push({ name: 'admin-career-jobs' });
    }
  },
  data() {
    return {
      visitors: [],
      loading: true,
    };
  },
  methods: {
    async loadVisitors() {
      const response = await this.$store.dispatch('showVisitorsByJobId', this.$route.params.jobId);
      return response.data;
    },
    async startDownloadDocument(item, documentType) {
      const response = await this.$store.dispatch('downloadDocument',
        {
          visitor: `${item.lastName}${item.firstName}-${documentType}`,
          hash: documentType === 'cv' ? item.cv : item.motivationLetter,
        });
    },
    sendEmail(email) {
      window.open(`mailto:${email}`);
    },
    phoneVisitor(phoneNumber) {
      window.open(`tel:${phoneNumber}`);
    },
  },
};
</script>

<style scoped>

</style>
